@charset "UTF-8";
* {
  padding: 0;
  margin: 0;
  list-style: none;
  font-style: normal;
  /*防止点击闪烁*/
  -webkit-tap-highlight-color: transparent;
  /*缩放网页，文字大小不变*/
  -webkit-text-size-adjust: none;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', 'PingFang SC', '微软雅黑', 'Microsoft YaHei', '华文细黑', 'STHeiti', sans-serif;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  font-weight: normal;
  touch-action: pan-y; }
  *:hover {
    outline: none; }

/* 设置iconfont标签font-family */
[class^="icon-"], [class*=" icon-"] {
  font-family: "iconfont";
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background-color: #f7f7f7; }

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0);
  border-radius: 10px;
  background-color: #f7f7f7; }

/*去除虚线*/
input:focus, select:focus, textarea:focus, button:focus {
  outline: none; }

input {
  border: none; }

/*背景色*/
html, body {
  height: 100%;
  width: 100%;
  background-color: #ffffff !important;
  color: #333333; }

input[type="button"], input[type="submit"], input[type="search"], input[type="reset"] {
  -webkit-appearance: none; }

a {
  text-decoration: none;
  /*ios禁止页面在新窗口打开*/
  -webkit-touch-callout: none; }

.float-left {
  float: left; }

.float-right {
  float: right; }

.clear {
  clear: both; }

.clearfix::after {
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden; }

.clearfix {
  zoom: 1; }

.mt15 {
  margin-top: 1.5rem; }

.mt12 {
  margin-top: 1.2rem; }

.mt8 {
  margin-top: .8rem; }

.mt6 {
  margin-top: .6rem; }

.mb6 {
  margin-bottom: .6rem; }

.pb6 {
  padding-bottom: .6rem; }

.border-bottom5 {
  border-bottom: .05rem dashed #333333; }

.border-top5 {
  border-top: .05rem solid #333333; }

.border-top6 {
  border-top: .06rem dashed #dddddd; }

.border-bottom6 {
  border-bottom: .06rem dashed #dddddd; }
